import React from 'react'
import Helmet from 'react-helmet'
import {graphql} from 'gatsby'
import Layout from '../layouts'
import Nav from '../components/nav'
import Article from '../components/contentful/article'
import Metadata from '../layouts/metadata'

const FirstPage = ({data, location}) => {
	const firstArticle = data.articles.edges[0].node

	return (
		<Layout location={location}>
			<Metadata
				title={firstArticle.title}
				keywords=""
				description={firstArticle.body.text.excerpt}
				image={firstArticle.illustration.file.url}
			/>
			<Helmet>
				<body className="first" />
			</Helmet>
			<Nav links={data.links} pathname={location.pathname} />
			<section className="welcome">
				<h2>Welcome to Boob Half Empty!</h2>
				<p>
					{' '}
					Come for the hastily drawn comics during baby’s nap time, stay for the
					reasonably-sized personal essays about life, motherhood, and raising a
					baby in Manhattan.
				</p>
				<p>
					{' '}
					I made this site because we should be able to speak openly and
					honestly about the impacts of childbirth and motherhood on life - and
					work to make it better.
				</p>

				<h3> A bit about me:</h3>
				<ul>
					<li>
						<p>I live in a tiny apartment in Manhattan</p>
					</li>
					<li>
						<p>
							I work in tech! I am a former designer who is now a software
							engineer
						</p>
					</li>
					<li>
						<p>I am over 30</p>
					</li>
					<li>
						<p>I love to travel</p>
					</li>
					<li>
						<p>I believe that everything worth doing is hard</p>
					</li>
				</ul>

				<h3>Core beliefs</h3>
				<ul>
					<li>
						<p>It doesn't get easier, you have to get better</p>
					</li>
					<li>
						<p>Empowered women empower women</p>
					</li>
					<li>
						<p>Everything worth doing is hard</p>
					</li>
					<li>
						<p>Put your oxygen mask on first</p>
					</li>
					<li>
						<p>Impact matters, not intention</p>
					</li>
				</ul>

				<p>
					Boob half empty, cup half full. Welcome! My first post below will fill
					you in on the rest.
				</p>
			</section>
			<Article {...firstArticle} />
		</Layout>
	)
}

export default FirstPage

export const query = graphql`
	query {
		links: boobLink(previous: {eq: ""}) {
			next
		}
		articles: allContentfulArticle(
			limit: 1
			sort: {fields: [createdAt], order: ASC}
		) {
			edges {
				node {
					title
					illustration {
						...ContentfulImageFragment
					}
					caption
					body {
						text: childMarkdownRemark {
							excerpt
							html
						}
					}
				}
			}
		}
	}
`
